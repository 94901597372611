import Api from "@/apis/Api";
import Dns from "../../src/apis/Dns"

export default {
	data() {
		return {
			dnsRecordsToSet: [],
		}
	},
	methods: {
		wpAdminSsoRedirect() {
			if (this.selectedService.id === 0) {
				return;
			}

			const timeout = this.selectedService.onboarding.super_quick_redirect_delay_after_site_creation * 1000;
			setTimeout(() => {
				Api.post(
					`/instances/${this.installedInstance.id}/sso-url`,
					{user: 1}
				).then(response => {
					//window.open(response.data.data.sso_url, '_blank');
					window.location.href = response.data.data.sso_url;
				})
					.catch((error) => {
						this.$store.dispatch("addAlert", {
							success: false,
							errorMessage: Api.getErrorMessage(error),
						});
					})
					.finally(() => {
						this.installationCompleted = true;
						this.installationError = false;
					})
			}, timeout);
		},

		verifyDns() {
			const domain = this.installedInstance.domain;
			const ipAddresses = this.installedInstance.host_ipv4_addresses;

			Dns.compareARecord(domain, ipAddresses)
				.then(result => {
					if (!result.matching) {
						for (let ipAddress of  this.installedInstance.host_ipv4_addresses) {
							this.dnsRecordsToSet.push(`${domain} A ${ipAddress}`);
						}
					}
				})
				.finally(() => {
					this.installationCompleted = true;
					this.installationError = false;
				})
		}
	}
}