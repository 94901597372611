export default {
  resolved: new Map(),
  resolveNSRecords(domain) {
    if (!this.resolved.has(domain)) {
      this.resolved.set(domain, fetch(`https://dns.google/resolve?name=${domain}&type=2`, {
        referrerPolicy: 'no-referrer',
        cache: 'no-cache',
      }).then((response) => {
        return response.json();
      }).then((jsonResponse) => {
        let ns = [];
        if (jsonResponse.Answer) {
          for (const a of jsonResponse.Answer) {
            if (a.type == 2) {
              ns.push(a.data);
            }
          }
        }
        if (
          ns.length === 0 &&
          (domain.split('.').length > 2) &&
          jsonResponse.Authority &&
          jsonResponse.Authority.length > 0
        ) {
          ns.push(jsonResponse.Authority[0].data.split(". ", 2)[0]);
        }
        return ns;
      }).catch(() => {
        return [];
      }));
    }
    return this.resolved.get(domain);
  },
  nsStatus(domain, nameservers) {
    return this.resolveNSRecords(domain).then((domainNs) => {
      if (domainNs.length === 0) {
        return "not-found"
      }
      for (const ns of domainNs) {
        if (!nameservers.includes(ns)) {
          return "mismatch";
        }
      }
      return "ok";
    });
  },
  aStatus(domain, ip_address) {
    return this.resolveARecord(domain).then((result) => {
      if (result === null) {
        return "not-found"
      }
      if (result != ip_address) {
        return "mismatch";
      }
      return "ok";
    });
  },
  resolveARecord(domain) {
    return fetch(`https://dns.google/resolve?name=${domain}&type=1`, {
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    }).then((response) => {
      return response.json();
    }).then((jsonResponse) => {
      if (jsonResponse.Answer) {
        for (const a of jsonResponse.Answer) {
          if (a.type == 1) {
            return a.data;
          }
        }
      }
      return null;
    }).catch(() => {
      return null;
    });
  },
  resolveCNAMERecord(domain) {
    return fetch(`https://dns.google/resolve?name=${domain}&type=5`, {
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    }).then((response) => {
      return response.json();
    }).then((jsonResponse) => {
      if (jsonResponse.Answer) {
        for (const a of jsonResponse.Answer) {
          if (a.type == 5) {
            return a.data;
          }
        }
      }
      return null;
    }).catch(() => {
      return null;
    });
  },
  async compareNSRecords(domain, compareWith) {
    let result = {
      resolving: false,
      matching: false,
    };
    await this
      .resolveNSRecords(domain)
      .then((nameservers) => {
        if (nameservers.length === 0) {
          return;
        }
        result.resolving = true;
        for (let ns of nameservers) {
          if (ns.endsWith(".")) {
            ns = ns.slice(0, -1);
          }
          if (!compareWith.includes(ns)) {
            return;
          }
        }
        result.matching = true;
        return;
      });

    if (result.matching) {
      return result;
    }
    if (domain.startsWith('www.')) {
      return this.compareNSRecords(domain.slice(4), compareWith);
    }
    return result;
  },
  async cloudflareNSDetected(domain) {
    let detected = await this
      .resolveNSRecords(domain)
      .then((nameservers) => {
        if (nameservers.length === 0) {
          return false;
        }
        for (let ns of nameservers) {
          if (ns.endsWith(".")) {
            ns = ns.slice(0, -1);
          }
          if (!ns.includes('cloudflare')) {
            return false;
          }
        }
        return true;
      });
    if (detected) {
      return detected;
    }
    if (domain.startsWith('www.')) {
      return this.cloudflareNSDetected(domain.slice(4));
    }
    return detected;
  },
  async compareCNAMERecord(domain, validCnameDomains) {
    let result = {
      resolving: false,
      matching: false,
    };
    await this
      .resolveCNAMERecord(domain)
      .then((response) => {
        if (!response) {
          return;
        }
        result.resolving = true;
        if (response.endsWith(".")) {
          response = response.slice(0, -1);
        }
        for (let validDomain of validCnameDomains) {
          if (response.endsWith("." + validDomain)) {
            result.matching = true;
            return;
          }
        }
      });

    if (result.matching) {
      return result;
    }
    if (domain.startsWith('www.')) {
      return this.compareCNAMERecord(domain.slice(4), validCnameDomains);
    }
    return result;
  },
  async compareARecord(domain, validIpAddresses) {
    let result = {
      resolving: false,
      matching: false,
    };
    await this
      .resolveARecord(domain)
      .then((response) => {
        if (!response) {
          return;
        }
        result.resolving = true;
        for (let validIp of validIpAddresses) {
          if (response == validIp) {
            result.matching = true;
            return;
          }
        }
      });

    if (result.matching) {
      return result;
    }
    if (domain.startsWith('www.')) {
      return this.compareARecord(domain.slice(4), validIpAddresses);
    }
    return result;
  },
}