<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()"/>

    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-title class="pb-0 flex justify-space-between align-center">
        <h4>
          {{ $t("heading.onboarding.transferDomain.title") }}
        </h4>
        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-card-title>

      <template v-if="selectedService.onboarding.domain_transfer_enabled">
        <v-card-text height="800px" class="px-10 pt-8">
          <h6 class="p-2 font-weight-light">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.subtitle')"
            >
              <template #domain><span class="font-weight-600">{{ installationDetails.domain }}</span></template>
            </i18next>
          </h6>

          <p class="p-3 mt-2">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.message.possible')"
            >
              <template #recordType><span class="font-weight-800">{{ installationDetails.dns_record.type }}</span>
              </template>
              <template #domain><span class="font-weight-800">{{ installationDetails.domain }}</span></template>
            </i18next>
          </p>

          <div class="recovery-code-box">
            {{ installationDetails.dns_record.content }}
            <copy-text-button
              :text="installationDetails.dns_record.content"
              :color="'primary'"
              size="24"
            />
          </div>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text height="800px" class="px-10 pt-8">
          <h6 class="p-2 font-weight-light">
            <i18next
              :translation="$t('heading.onboarding.transferDomain.subtitle')"
            >
              <template #domain><span class="font-weight-600">{{ installationDetails.domain }}</span></template>
            </i18next>
          </h6>
          <p class="p-3 mt-2">
            {{ $t('heading.onboarding.transferDomain.message.impossible') }}
          </p>
        </v-card-text>
      </template>

      <v-card-actions class="d-flex flex-column pb-6 px-10 pt-2 mt-4">
        <template v-if="selectedService.onboarding.domain_transfer_enabled">
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            @click="handleInstallInstance"
            :loading="submitting"
          >{{ $t('button.transferNow') }}
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            class="mx-0 mt-2"
            color="gray"
            text
            block
            @click="$emit('removeModal')"
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              {{ $t("button.back") }}
            </span>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            @click="$emit('removeModal')"
          >{{ $t('button.dashboard.back') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CopyTextButton from "@/components/buttons/CopyTextButton.vue";
import Api from "@/apis/Api";

export default {
  name: "OnboardingVerificationDomainModal",
  components: {CopyTextButton},
  props: {
    isOpen: Boolean,
    selectedService: Object,
    installationDetails: Object
  },
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    handleInstallInstance() {
      if (this.installationDetails.install_method === 'standard') {
        this.installWebsite();
        return;
      }
      this.$emit("installWebsite", this.installationDetails);
    },
    installWebsite() {
      this.submitting = true;

      Api.post("/instances/install", this.installationDetails)
        .then((response) => {
          this.submitting = false;
          this.isPersistent = false;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.installation.created')
          });
          this.$root.$emit("instance-created", response.data.data);
          this.$emit("removeModal");
        })
        .catch((error) => {
          this.submitting = false;

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        });
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
}
</script>


<style lang="scss" scoped>
.recovery-code-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: var(--v-primary-base);
  background: rgba(7, 192, 126, 0.05);
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  word-wrap: anywhere;
  padding: 8px;
  min-height: 80px !important;
}

.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}

.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }

  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}

</style>